import React, { useEffect, useRef, useState, useMemo } from "react";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import VpnKeyTwoToneIcon from "@material-ui/icons/VpnKeyTwoTone";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useCookies } from "react-cookie";

export const Auth = ({ setAuth }) => {
  const baseUrl =
    process.env.NODE_ENV === "production"
      ? "https://europe-west3-wizard-1fda1.cloudfunctions.net"
      : "http://localhost:5001/wizard-1fda1/us-central1";
  const [xhrLoading, setXhrLoading] = useState(false);
  const [xhrErrorMessage, setXhrErrorMessage] = useState(null);
  const authenticateByCookie = useMemo(() => {
    setXhrLoading(true);
    // create a new XMLHttpRequest
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    // get a callback when the server responds
    xhr.addEventListener("load", () => {
      // update the state of the component with the result here
      setXhrLoading(false);
      setAuth(xhr.status === 200);
    });
    xhr.addEventListener("error", () => {
      setXhrLoading(false);
      setAuth(false);
      setXhrErrorMessage("Server error.");
    });

    // open the request with the verb and the url
    xhr.open("GET", baseUrl + "/authByCookie");
    // send the request
    xhr.send();
  }, []);

  const authenticateByPw = (pw) => {
    setXhrLoading(true);
    // create a new XMLHttpRequest
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    // get a callback when the server responds
    xhr.addEventListener("load", () => {
      // update the state of the component with the result here
      setXhrLoading(false);
      setXhrErrorMessage(xhr.status === 401 ? "Falsches Passwort" : null);
      setAuth(xhr.status === 200);
    });
    xhr.addEventListener("error", () => {
      setXhrLoading(false);
      setAuth(false);
      setXhrErrorMessage("Server error.");
    });

    // open the request with the verb and the url
    xhr.open("GET", `${baseUrl}/authByPw?pw=${pw.hashCode()}`, true);
    // send the request
    xhr.send();
  };

  useEffect(() => {
    if (typeof authenticateByCookie === "function") authenticateByCookie();
  }, [authenticateByCookie]);
  const auth = (e) => {
    e.preventDefault();
    authenticateByPw(pwRef.current.value);
    pwRef.current.value = null;
  };
  const pwRef = useRef(null);
  return (
    <>
      {xhrLoading && (
        <div className="password-loading-overlay">
          <CircularProgress />
        </div>
      )}
      <h3>Zugangspasswort</h3>
      <form noValidate autoComplete="off" onSubmit={auth}>
        <TextField id="standard-basic" inputRef={pwRef} label="Passwort" />

        <IconButton color="primary" aria-label="Go" type="submit">
          <VpnKeyTwoToneIcon />
        </IconButton>
      </form>
      {xhrErrorMessage && (
        <small style={{ color: "#c7050" }}>{xhrErrorMessage}</small>
      )}
    </>
  );
};
