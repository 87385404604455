import React, { PropsWithChildren } from 'react';

interface ITinyPlayCardProps {
  identifier: string;
  color: "red" | "blue" | "yellow" | "green" | "black";
  played: boolean;
  className?: string;
  onClick?: any;
}

const colorClass = (color: "red" | "blue" | "yellow" | "green" | "black", roundOne?: boolean) => {
  if (roundOne) return "bg-gray-800"
  switch (color) {
    case "red": return "bg-red-800";
    case "blue": return "bg-blue-700";
    case "yellow": return "bg-yellow-600";
    case "green": return "bg-green-700";
    default: return "bg-gray-900"
  }
}

const textColorClass = (color: "red" | "blue" | "yellow" | "green" | "black", roundOne?: boolean) => {
  if (roundOne) return "text-gray-400"
  switch (color) {
    case "red": return "text-red-100";
    case "blue": return "text-blue-100";
    case "yellow": return "text-yellow-100";
    case "green": return "text-green-100";
    default: return "text-gray-100"
  }
}

function TinyPlayCard(props: PropsWithChildren<ITinyPlayCardProps>) {
  const number = props.identifier;
  return (
    <div onClick={props.onClick} className={`${props.className} ml-2 select-none h-12 w-8 md:h-16 md:w-10 mr-2 flex flex-col justify-center rounded-md `
      + colorClass(props.color) + " " + textColorClass(props.color)}>
      <div className="h-full flex items-center justify-center">
        <div className="h-8/12 flex items-center justify-center">
          <div className="text-xl md:text-2xl font-bold">{number}</div>
        </div>
      </div>
    </div>
  );
}

export default TinyPlayCard;
