import React, { useEffect, useCallback } from "react";
import { useState, useRef } from "react";
import { updateGameSession } from "../dataservices/gameSessionSerivce";
import UserBubble from "./UserBubble";
import PlayCard from "./PlayCard";
import { useToasts } from "react-toast-notifications";
import { sumBy } from "lodash";
import Countdown from "./CountDown";

import TinyPlayCard from "./TinyPlayCard";
import { getNextPlayerByIndex } from "./../services/PlayerService";
import FieldCard from "./FieldCard";
import {
  calcRoundWinner,
  serveCards,
  nextRound,
  maxRounds,
} from "../services/GameLogic";
import { DeckColorTranslations } from "../services/DeckService";
import { FormControlLabel, Switch } from "@material-ui/core";

export const MyHand = ({ activeSession, activeUser }) => {
  const [raised, setRaised] = useState();
  const [sortCards, setSortCards] = useState(false);
  const handleSortChange = (e) => {
    setSortCards(e.target.checked);
  };
  const user = activeSession.player.find((x) => x.id === activeUser);
  const allCardsPlayed =
    activeSession.centerCardStack.length > 0 &&
    activeSession.centerCardStack.length === activeSession.player.length;
  const { addToast } = useToasts();
  const timeoutRef = useRef(null);

  const activeSessionRef = useRef(activeSession);
  activeSessionRef.current = activeSession;

  useEffect(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    if (user.dealer && allCardsPlayed) {
      timeoutRef.current = setTimeout(
        () => calcRoundWinner(activeSessionRef.current, activeUser),
        3000
      );
    }
  }, [activeUser, allCardsPlayed, user.dealer]);

  const updateRaised = (id) => {
    if (raised !== id) {
      setRaised(id);
    } else {
      if (activeSession.whoseTurn !== user.id) {
        const nextUser = activeSession.player.find(
          (x) => x.id === activeSession.whoseTurn
        );
        if (nextUser) {
          addToast(`${nextUser.name} ist am Zug 🧙`, {
            appearance: "warning",
            autoDismiss: true,
          });
        } else {
          addToast(`Bitte warten...`, {
            appearance: "warning",
            autoDismiss: true,
          });
        }
        return;
      }
      if (activeSession.shouldSetStrikes !== null) {
        addToast(`Bitte warte bis alle Ihre Stiche angesagt haben 🧙`, {
          appearance: "warning",
          autoDismiss: true,
        });
        return;
      }
      const cardToPlay = user.handCards.find((x) => x.id === id);
      if (cardToPlay.color !== "white") {
        if (activeSession.centerCardStack.length) {
          const colorToServe =
            activeSession.centerCardStack[0].number === "Z"
              ? null
              : activeSession.centerCardStack.find((c) => c.color !== "white")
                  ?.color;
          if (
            colorToServe &&
            cardToPlay.color !== colorToServe &&
            Boolean(user.handCards.find((c) => c.color === colorToServe))
          ) {
            addToast(
              `Du musst ${DeckColorTranslations[colorToServe]} bedienen 🧙`,
              {
                appearance: "warning",
                autoDismiss: true,
              }
            );
            return;
          }
        }
      }
      user.playedCard = cardToPlay;
      user.playedCard = user.handCards.find((x) => x.id === id);
      user.handCards = user.handCards.filter((x) => x.id !== id);
      activeSession.centerCardStack.push({
        ...user.playedCard,
        playedBy: user.id,
      });
      const nexPlayer = getNextPlayerByIndex(
        activeSession.player,
        activeSession.player.findIndex((x) => x.id === user.id)
      );
      if (nexPlayer.handCards.length) {
        activeSession.whoseTurn = nexPlayer.id;
      } else {
        activeSession.whoseTurn = null;
      }
      if (
        activeSession.centerCardStack.length === activeSession.player.length
      ) {
        activeSession.whoseTurn = null;
      }
      setRaised(null);
      updateGameSession(activeSession.id, activeSession);
    }
  };
  const scoreObj = user.scores.find(
    (x) => x.round === activeSession.activeRound
  );
  const calledStrikes =
    scoreObj?.calledStrikes !== undefined
      ? scoreObj?.calledStrikes === 0
        ? 0
        : scoreObj?.calledStrikes
      : "?";

  const sortF = useCallback(
    (ob1, ob2) => {
      if (!sortCards) return 0;
      return ob2.id - ob1.id;
    },
    [sortCards]
  );
  return (
    <>
      <div
        className="flex flex-col justify-center items-stretch relative flex-1 md:flex-1"
        // style={{ flex: "0.4" }}
      >
        {allCardsPlayed && <Countdown countDownFrom={3} />}
        <div className="flex justify-around items-center pt-1 pb-5 md:pb-6">
          <div
            className="self-center"
            style={{ display: "flex", alignItems: "center" }}
          >
            <UserBubble
              name={user.name}
              tricks={user.strikes}
              calledTricks={calledStrikes}
              hasTurn={activeSession.whoseTurn === user.id}
              isDealer={user.dealer}
              shouldSetStrikes={activeSession.shouldSetStrikes}
              userId={activeUser}
            />
            {user.playedCard && (
              <TinyPlayCard
                identifier={user.playedCard.number}
                color={user.playedCard.color}
              />
            )}
          </div>
          {user.dealer && (
            <>
              {user.handCards.length === 0 && !activeSession.cardsServed && (
                <button
                  className="bg-gray-800 text-gray-200 p-3 rounded-md uppercase md:text-base text-xs tracking-wider hover:bg-gray-600"
                  onClick={() => serveCards(activeSession)}
                >
                  {activeSession.activeRound === 1
                    ? "Start"
                    : "Karten verteilen"}
                </button>
              )}
              {activeSession.cardsServed &&
                sumBy(activeSession.player, (p) => p.strikes) ===
                  parseInt(activeSession.activeRound) && (
                  <button
                    className="bg-gray-800 text-gray-200 p-3 rounded-md md:text-base text-xs uppercase tracking-wider hover:bg-gray-600"
                    onClick={() => nextRound(activeSession)}
                  >
                    {activeSession.activeRound ===
                    maxRounds(activeSession.player.length)
                      ? "Punkte berechnen"
                      : "Nächste Runde"}
                  </button>
                )}
            </>
          )}
        </div>
        <div
          className={`flex bg-gray-400 items-center flex-1 ${
            activeSession.whoseTurn === activeUser &&
            !activeSession.shouldSetStrikes
              ? "pulse"
              : ""
          }`}
        >
          {activeSession.lastStrike.cards?.length > 0 && (
            <>
              <div className="w-20 md:w-auto flex flex-col justify-center items-center p-2 md:ml-0">
                <div className="uppercase tracking-wide text-gray-600 font-bold text-xs md:text-base leading-none pb-1">
                  Letzter Stich ({activeSession.lastStrike.winner})
                </div>
                <div className="flex flex-col md:flex-row">
                  {activeSession.lastStrike.cards.map((c, i) => (
                    <FieldCard
                      key={`laststrikecard_${c.id}`}
                      color={c.color}
                      identifier={c.number}
                      className={i !== 0 ? "-mt-20 md:-ml-24 md:mt-0" : ""}
                    />
                  ))}
                </div>
              </div>
              <div className="w-05 bg-gray-500 py-2 h-11/12 md:mx-2"></div>
            </>
          )}

          {user.handCards.length > 0 && (
            <>
              <FormControlLabel
                style={{
                  position: "absolute",
                  top: "70px",
                  right: 0,
                }}
                control={
                  <Switch
                    checked={sortCards}
                    onChange={handleSortChange}
                    name="sortHandCards"
                    color="primary"
                  />
                }
                label="Sortieren"
              />
              <div
                className="flex flex-wrap md:flex-no-wrap justify-center items-center flex-grow py-2"
                onClick={() => {
                  setRaised(null);
                }}
              >
                {user.handCards.sort(sortF).map((card, i) => (
                  <PlayCard
                    {...card}
                    raised={raised}
                    onClick={(e) => {
                      e.stopPropagation();
                      return updateRaised(card.id);
                    }}
                    roundone={activeSession.activeRound === 1}
                    key={`card_${card.id}_${sortCards}`}
                    identifier={card.number}
                    className={`${i === user.handCards.length - 1 && "mr-2"}`}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
