export const getShuffledDeck = () => {
  const colors = ["blue", "yellow", "red", "green"];
  const numberWizards = 4;
  const numberNoobs = 4;
  const deck = [];
  let id = 0;
  colors.forEach((color) => {
    for (let index = 1; index < 14; index++) {
      deck.push({ number: index, color, img: color, id });
      id++;
    }
  });

  for (let index = 0; index < numberNoobs; index++) {
    deck.push({
      number: "N",
      color: "white",
      img: "n",
      id,
    });

    id++;
  }
  for (let index = 0; index < numberWizards; index++) {
    deck.push({
      number: "Z",
      color: "white",
      img: "z",
      id,
    });

    id++;
  }
  return shuffle(deck);
};

const shuffle = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

// export const shuffleTest = () => {
//   let i = 100
//   const res = []
//   while(i > 0){
//     const deck = getShuffledDeck();
//     res.push(deck.reduce(function (a, e, i) {
//       if (e.number === 'Z')
//         a.push(i);
//       return a;
//     }, []));

//     i--
//   }
// }

export const DeckColorTranslations = {
  blue: "blau",
  red: "rot",
  yellow: "gelb",
  green: "green",
  white: "farblos",
};
