export const getNextPlayerByIndex = (player, myIndex) => {
  if (player.length - 1 === myIndex) {
    return player[0];
  } else {
    return player[myIndex + 1];
  }
};
export const getNextPlayer = (player, myId) => {
  const myIndex = player.findIndex(x => x.id === myId);
  return getNextPlayerByIndex(player, myIndex);
};
