import React from "react";
import { useEffect } from "react";
import { useState, useRef } from "react";
import {
  deleteGameSession,
  updateGameSession,
} from "../dataservices/gameSessionSerivce";
import { List, ListItem, Grid, Container } from "@material-ui/core";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TextField from "@material-ui/core/TextField";
import { createGameSession } from "../dataservices/gameSessionSerivce";
import { useToasts } from "react-toast-notifications";

import { observeCollection, observeDoc } from "../dataservices/observer";
import Moment from "react-moment";

import { CookiesProvider } from "react-cookie";
import { Auth } from "../Components/Auth";

export const Lobby = ({ setActiveGameSession, activeGameSession, setUser }) => {
  const [sessions, setSessions] = useState([]);
  const [activeSession, setActiveSession] = useState(null);
  const [isAuth, setAuth] = useState();
  const { addToast } = useToasts();

  useEffect(() => {
    if (activeGameSession) {
      const unsub = observeDoc(
        "gameSessions",
        activeGameSession,
        (docSnapshot) => {
          const session = { ...docSnapshot.data(), id: activeGameSession };

          setActiveSession(session);
        }
      );
      return unsub;
    }
  }, [activeGameSession, setActiveSession]);

  useEffect(() => {
    const unsub = observeCollection("gameSessions", (querySnapshot) => {
      const sessions = querySnapshot.docs.map((docSnapshot) => {
        return {
          ...docSnapshot.data(),
          id: docSnapshot.id,
        };
      });
      setSessions(sessions);
    });
    return unsub;
  }, [setSessions]);

  const addGameSession = (e) => {
    e.preventDefault();
    createGameSession(inputRef.current.value);
    inputRef.current.value = null;
  };
  const inputRef = useRef(null);
  const inputUserRef = useRef(null);
  const saveDelete = (id) => {
    return window.confirm("Löschen?") ? deleteGameSession(id) : null;
  };
  const deleteUser = (id) => {
    activeSession.player = activeSession.player.filter((p) => p.id !== id);
    updateGameSession(activeGameSession, activeSession);
  };
  const saveDeleteUser = (id) => {
    return window.confirm("Löschen?") ? deleteUser(id) : null;
  };

  const setGameSession = (id) => {
    setActiveGameSession(id);
  };

  const addUser = (e) => {
    e.preventDefault();
    if (activeSession.activeRound !== 1 || activeSession.cardsServed) {
      addToast(
        `Diese Runde hat bereits angefangen, daher können keine Spieler mehr beitreten.`,
        {
          appearance: "warning",
          autoDismiss: true,
        }
      );
      return;
    }
    const id = new Date().getTime().toString();
    const dealer = activeSession.player.length === 0;
    activeSession.player.push({
      name: inputUserRef.current.value,
      id,
      dealer,
      handCards: [],
      strikes: 0,
      playedCard: null,
      scores: [],
    });
    inputUserRef.current.value = "";
    updateGameSession(activeGameSession, activeSession);
  };
  return (
    <Container
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <video autoPlay muted loop id="myVideo" playsInline>
        <source src="./img/night_hill.mp4" type="video/mp4" />
      </video>
      <Grid
        item
        xs={12}
        md={6}
        style={{
          backgroundColor: "#ffffffd6",
          padding: 16,
          zIndex: "6",
          position: "relative",
        }}
      >
        {!isAuth ? (
          <CookiesProvider>
            <Auth setAuth={setAuth}></Auth>
          </CookiesProvider>
        ) : !activeGameSession ? (
          <>
            <h3>Session auswählen</h3>
            <List>
              {sessions
                .sort((a, b) => {
                  return b.created - a.created;
                })
                .map((s) => (
                  <ListItem
                    button
                    onClick={() => setGameSession(s.id)}
                    key={`session_${s.id}`}
                  >
                    <ListItemText
                      primary={s.alias}
                      secondary={
                        <>
                          <Moment fromNow>{new Date(s.created)}</Moment>
                        </>
                      }
                    />
                    <ListItemSecondaryAction onClick={() => saveDelete(s.id)}>
                      <IconButton edge="end" aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            </List>
            <hr></hr>
            <form noValidate autoComplete="off" onSubmit={addGameSession}>
              <TextField
                id="standard-basic"
                inputRef={inputRef}
                label="Neue Session"
              />

              <IconButton color="primary" aria-label="Hinzufügen" type="submit">
                <AddIcon />
              </IconButton>
            </form>
          </>
        ) : (
          activeSession && (
            <>
              <h3>
                <IconButton onClick={() => setGameSession(null)}>
                  <ArrowBackIcon></ArrowBackIcon>
                </IconButton>
                Spieler
              </h3>
              <List>
                {activeSession.player.sort().map((u) => (
                  <ListItem
                    button
                    onClick={() => setUser(u.id + "")}
                    key={`user_${u.id}`}
                  >
                    <ListItemText primary={u.name} />
                    <ListItemSecondaryAction
                      onClick={() => saveDeleteUser(u.id)}
                    >
                      <IconButton edge="end" aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <hr></hr>
              <form noValidate autoComplete="off" onSubmit={addUser}>
                <TextField
                  id="standard-basic"
                  inputRef={inputUserRef}
                  label="Mein Spielername"
                />

                <IconButton
                  color="primary"
                  aria-label="Beitreten"
                  type="submit"
                >
                  <AddIcon />
                </IconButton>
              </form>
            </>
          )
        )}
      </Grid>
    </Container>
  );
};
