import React from "react";
import {useState, useEffect, useRef} from 'react';

function Countdown(props) {
  const [digit, setDigit] = useState(props.countDownFrom);
  const [toggleClass, setToggleClass] = useState(false);
  const timeoutRef = useRef();
  const intervalRef = useRef();
  useEffect(() => {
    if(timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
      }
      if(digit > 0){
        timeoutRef.current = setTimeout(() => {
          setDigit(digit-1);          
        }, 1000);
        
      }
      return () => {clearTimeout(timeoutRef.current)}  
  }, [digit]);

  useEffect(() => {
    if(intervalRef.current !== null){
      clearInterval(intervalRef.current)
      intervalRef.current = setInterval(() => setToggleClass(!toggleClass), 500);
    }
    return () => {clearInterval(intervalRef.current)}  
  }, [toggleClass])
  
  return (
    <div className={"z-50 top-0 left-1/2 absolute -mt-10 md:-mt-24 -ml-2 md:-ml-3 select-none"} >
      <div className={"text-3xl leading-none text-gray-300 duration-500 transform transition "
     + (toggleClass && "opacity-0 scale-75") + " "  + (!toggleClass && "opacity-100 scale-150")}>
        {digit}
      </div>
    </div>
  );
}

export default Countdown;
