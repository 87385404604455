import * as firebase from "firebase";

const config = {
  apiKey: "AIzaSyDc-iyu0okRRKSAYfKoClrYk8m9tvHuT_0",
  authDomain: "wizard-1fda1.firebaseapp.com",
  databaseURL: "https://wizard-1fda1.firebaseio.com",
  projectId: "wizard-1fda1",
  storageBucket: "wizard-1fda1.appspot.com",
  messagingSenderId: "675546990692",
  appId: "1:675546990692:web:e481183f929f40ca7c7771"
};
firebase.initializeApp(config);
export const db = firebase.firestore();
export const firestore = firebase.firestore;
