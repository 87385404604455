import React, { Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import { maxRounds } from "../services/GameLogic";

export const Scoresheet2 = ({ activeSession }) => {
  const player = activeSession.player;
  const tableBody = [];
  // const myChangeHandler = event => {
  //   let round = parseInt(event.target.dataset.round);
  //   let playerid = event.target.dataset.playerid;
  //   const currentPlayer = activeSession.player.find(x => x.id === playerid);
  //   const scoreObj = currentPlayer.scores?.find(x => x.round === round) || {};
  //   const isNewEntry = scoreObj.calledStrikes === undefined;
  //   scoreObj.calledStrikes = parseInt(event.target.value);
  //   scoreObj.round = round;
  //   if (isNewEntry) currentPlayer.scores.push(scoreObj);

  //   updateGameSession(activeSession.id, activeSession);
  // };

  for (let index = 1; index <= maxRounds(player.length); index++) {
    const tds = [];

    player.forEach((p, playerI) => {
      const { scores } = p;
      const scoreObj = scores?.find((x) => x.round === index);

      const calledStrikes = scoreObj?.calledStrikes;
      const points = scoreObj?.points;
      tds.push(
        <Fragment key={`scoretds_${index}_${p.id}`}>
          <td
            className="tdCalledStrikes"
            style={{ borderRight: "1px dotted black" }}
          >
            <TextField
              type="number"
              inputProps={{
                style: { textAlign: "center" },
              }}
              disabled
              value={calledStrikes}
            />
          </td>
          <td style={{ borderRight: "2px solid black" }}>
            <TextField
              type="number"
              inputProps={{
                style: { textAlign: "center" },
              }}
              value={points}
              disabled
            />
          </td>
        </Fragment>
      );
    });
    const tr = (
      <tr key={`counter_${index}`}>
        <td
          width="20"
          style={{
            borderRight: "1px solid black",
            background:
              activeSession.activeRound === index ? "lightgray" : "white",
          }}
        >
          <TextField
            value={index}
            disabled
            inputProps={{ style: { textAlign: "center" } }}
          />
        </td>
        {tds}
      </tr>
    );
    tableBody.push(tr);
  }
  return (
    <table style={{ background: "white" }}>
      <thead>
        <tr>
          <th
            style={{
              borderRight: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            #
          </th>
          {player.map((p) => (
            <th
              key={`playerName_${p.id}`}
              colSpan="2"
              style={{
                borderBottom: "2px solid black",
                borderRight: "2px solid black",

                backgroundColor: p.dealer ? "lightgray" : "white",
              }}
            >
              {p.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{tableBody}</tbody>
    </table>
  );
};
