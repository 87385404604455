import React, { PropsWithChildren, useMemo } from "react";

interface IUserBubbleProps {
  name: string;
  tricks: number;
  calledTricks: number;
  hasTurn?: boolean;
  isDealer: boolean;
  shouldSetStrikes: string | null;
  userId: string;
}

function UserBubble(props: PropsWithChildren<IUserBubbleProps>) {
  const bubbleCharacters = useMemo(() => {
    const split: string[] = props.name
      .replace(/  +/g, " ")
      .split(" ")
      .slice(0, 2);
    return split.map((s) => s.trim().charAt(0) || "").join("");
  }, [props.name]);
  return (
    <div
      className={
        "rounded-full h-12 w-12 md:h-20 md:w-20 bg-gray-400 flex justify-center items-center text-gray-700 text-xl md:text-3xl font-bold uppercase relative md:border-6 border-4 border-gray-400 " +
        (props.hasTurn && !props.shouldSetStrikes
          ? "border-orange-600"
          : props.isDealer
          ? "border-gray-600"
          : "")
      }
      style={{ zIndex: 5 }}
    >
      <div>{bubbleCharacters}</div>
      <div
        className={`absolute bottom-0 -mb-5 bg-gray-600 text-gray-200 flex justify-center font-semibold items-center rounded-full w-10 h-6 md:w-12 md:h-8 text-xs md:text-lg leading-none ${
          props.shouldSetStrikes &&
          props.shouldSetStrikes === props.userId &&
          "border-4 border-orange-600"
        }`}
      >
        {props.tricks}/{props.calledTricks}
      </div>
    </div>
  );
}

export default UserBubble;
