import { db } from "./firebase";

export const observeCollection = (collectionName, observer) => {
  const collection = db.collection(collectionName);
  return collection.onSnapshot({
    next: observer,
    error: () => alert("error"),
  });
};

export const observeDoc = (collectionName, id, observer) => {
  const collection = db.collection(collectionName).doc(id);
  return collection.onSnapshot({
    next: observer,
    error: () => alert("error"),
  });
};
