import { db } from "./firebase";
import { getShuffledDeck } from "../services/DeckService";
const collection = db.collection("gameSessions");

export const getGameSession = (id) => {
  return collection.doc(id).get();
};
// export const observeGameSessions = observer => {
//   return collection.onSnapshot(observer);
// };
// export const observeGameSession = (id, observer) => {
//   return collection.doc(id).onSnapshot(observer);
// };
export const createGameSession = (alias) => {
  const cards = getShuffledDeck();
  const created = new Date().getTime();
  return collection.add({
    created: created,
    // createdBy: userId,
    alias,
    player: [],
    activeRound: 1,
    cards,
    scoreSheet: [],
    trumpCard: null,
    cardsServed: false,
    centerCardStack: [],
    whoseTurn: null,
    lastStrike: {},
    shouldSetStrikes: null,
  });
};

export const updateGameSession = (id, newSession) => {
  return collection.doc(id).set({ ...newSession }, { merge: true });
};

export const deleteGameSession = (id) => {
  return collection.doc(id).delete();
};
