import {
  getNextPlayerByIndex,
  getNextPlayer,
} from "./../services/PlayerService";
import { getShuffledDeck } from "../services/DeckService";
import { updateGameSession } from "../dataservices/gameSessionSerivce";

export const serveCards = (activeSession) => {
  const dealerIndex = activeSession.player.findIndex((x) => x.dealer);
  const nextPlayer = getNextPlayerByIndex(activeSession.player, dealerIndex);
  activeSession.whoseTurn = nextPlayer.id;
  activeSession.shouldSetStrikes = nextPlayer.id;
  activeSession.player.forEach((player) => {
    for (let index = 0; index < activeSession.activeRound; index++) {
      player.handCards.push(
        activeSession.cards.splice(
          Math.floor(Math.random() * activeSession.cards.length),
          1
        )[0]
      );
    }
  });
  if (activeSession.cards.length) {
    activeSession.trumpCard = activeSession.cards.pop();
  }
  activeSession.cardsServed = true;

  updateGameSession(activeSession.id, activeSession);
};

const calcPoints = (activeSession) => {
  const { activeRound } = activeSession;
  activeSession.player.forEach((p) => {
    const { scores } = p;

    const prevPoints =
      scores.find((x) => x.round === activeRound - 1)?.points || 0;
    const currentScoreObj = scores.find((x) => x.round === activeRound);
    const calledStrikes = currentScoreObj?.calledStrikes || 0;

    currentScoreObj.points =
      calledStrikes === p.strikes
        ? prevPoints + 20 + 10 * p.strikes
        : prevPoints - Math.abs(calledStrikes - p.strikes) * 10;
  });

  return activeSession;
};

export const setStrikesForPlayer = (activeSession, playerId, strikes) => {
  const round = activeSession.activeRound;
  const currentPlayer = activeSession.player.find((x) => x.id === playerId);
  const scoreObj = currentPlayer.scores?.find((x) => x.round === round) || {};
  const isNewEntry = scoreObj.calledStrikes === undefined;
  scoreObj.calledStrikes = strikes;
  scoreObj.round = round;
  if (isNewEntry) currentPlayer.scores.push(scoreObj);
  const nextPlayer = getNextPlayer(activeSession.player, playerId);
  activeSession.shouldSetStrikes =
    nextPlayer.id !== activeSession.whoseTurn ? nextPlayer.id : null;

  updateGameSession(activeSession.id, activeSession);
};

export const numberOfScores = [20, 20, 20, 15, 12, 10];
export const maxRounds = (playerLength) => numberOfScores[playerLength - 1];
export const nextRound = (activeSession) => {
  activeSession = calcPoints(activeSession);
  if (
    activeSession.activeRound ===
    numberOfScores[activeSession.player.length - 1]
  ) {
    updateGameSession(activeSession.id, activeSession);
    return;
  }
  let dealerindex = activeSession.player.findIndex((x) => x.dealer) + 1;
  if (dealerindex === activeSession.player.length) dealerindex = 0;
  activeSession.player.forEach((player, i) => {
    if (i === dealerindex) player.dealer = true;
    else player.dealer = false;
    player.handCards = [];
    player.strikes = 0;
    player.playedCard = null;
  });
  activeSession.trumpCard = null;
  activeSession.activeRound++;
  activeSession.cards = getShuffledDeck();
  activeSession.cardsServed = false;
  activeSession.centerCardStack = [];
  activeSession.lastStrike = {};
  activeSession.whoseTurn = null;
  updateGameSession(activeSession.id, activeSession);
};

const setStrikeWinnder = (activeSession, userId) => {
  const updateUser = activeSession.player.find((x) => x.id === userId);
  updateUser.strikes++;
  let overallStrikes = 0;
  activeSession.player.forEach((player, i) => {
    player.playedCard = null;
    overallStrikes += player.strikes;
  });
  if (overallStrikes === activeSession.activeRound)
    activeSession.trumpCard = null;
  activeSession.lastStrike = {
    cards: activeSession.centerCardStack,
    winner: updateUser.name,
  };
  activeSession.centerCardStack = [];
  if (overallStrikes === activeSession.activeRound) {
  } else {
    activeSession.whoseTurn = userId;
  }
  updateGameSession(activeSession.id, activeSession);
};
export const calcRoundWinner = (activeSession, activeUser) => {
  const user = activeSession.player.find((x) => x.id === activeUser);
  const allCardsPlayed =
    activeSession.centerCardStack.length > 0 &&
    activeSession.centerCardStack.length === activeSession.player.length;
  if (!allCardsPlayed || !user.dealer) return;
  let currentWinner, colorToServe, highestValue, trumpOverNonTrump;
  const trumpColor = activeSession.trumpCard
    ? activeSession.trumpCard.color === "white"
      ? null
      : activeSession.trumpCard.color
    : null;
  for (let index = 0; index < activeSession.centerCardStack.length; index++) {
    const card = activeSession.centerCardStack[index];
    if (card.number === "Z") {
      currentWinner = card.playedBy;
      break;
    }
    if (index === 0) {
      colorToServe = card.color;
      currentWinner = card.playedBy;
      highestValue = card.number;
      continue;
    } else {
      if (highestValue === "N" && card.number !== "N") {
        colorToServe = card.color;
        currentWinner = card.playedBy;
        highestValue = card.number;
        continue;
      } else if (highestValue === "N" && card.number === "N") {
        continue;
      }
      if (
        card.color === colorToServe &&
        parseInt(card.number) > parseInt(highestValue) &&
        (colorToServe === trumpColor || !trumpOverNonTrump)
      ) {
        currentWinner = card.playedBy;
        highestValue = card.number;
        continue;
      }
      if (card.color === trumpColor && colorToServe !== trumpColor) {
        if (!trumpOverNonTrump) {
          currentWinner = card.playedBy;
          highestValue = card.number;
          trumpOverNonTrump = true;
          continue;
        }
        if (parseInt(card.number) > parseInt(highestValue)) {
          currentWinner = card.playedBy;
          highestValue = card.number;
        }
      }
    }
  }

  //   addToast(
  //     `${activeSession.player.find(
  //       x => x.id === currentWinner
  //     )} gewinnt den Stich.`,
  //     {
  //       appearance: "success",
  //       autoDismiss: true
  //     }
  //   );
  console.log(
    `${
      activeSession.player.find((x) => x.id === currentWinner).name
    } gewinnt den Stich.`
  );
  return setStrikeWinnder(activeSession, currentWinner);
};
